import CIcon from '@coreui/icons-react'
import { ClientIcon, ProductIcon, PurchaseOrderIcon, VendorIcon, WarehouseIcon, WorkReportIcon } from 'src/assets/svg_icons';

export const _moduleadminNav = {
    dashboardNav: [
        {
            _tag: 'CSidebarNavItem',
            name: 'Dashboard',
            to: `/dashboard`,
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
            exact: false,
        },
    ],
    _moduleadminNav: [
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Manage Module',
            route: '/base',
            icon: 'cil-people',
            _children: [
                // {
                //     _tag: 'CSidebarNavItem',
                //     icon: <CIcon name='cil-building' className='mr-2' customClasses="c-sidebar-nav-icon" />,
                //     name: 'DWR',
                //     to: '/base/tooltips',
                //     code: 'dwr',
                //     exact: false,
                // },
                {
                    _tag: 'CSidebarNavDropdown',
                    name: 'Operations Manager',
                    route: '/cash-voucher',
                    code: 'operations',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Items',
                            icon: <ProductIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/products',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Warehouse',
                            icon: <WarehouseIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/warehouse',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Vendors',
                            icon: <VendorIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/vendors',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Purchase Order',
                            icon: <PurchaseOrderIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/purchase-order',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Clients',
                            icon: <ClientIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/clients',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Inventory',
                            icon: <CIcon name='cil-building' className='mr-2' />,
                            to: '/inventory-list',
                            exact: false,
                        },
                    ],
                },
                {
                    _tag: 'CSidebarNavDropdown',
                    name: 'Operations Manager',
                    route: '/cash-voucher',
                    code: 'inventory',
                    icon: <CIcon name='cil-building' className='mr-2' />,
                    _children: [
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Items',
                            icon: <ProductIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/products',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Warehouse',
                            icon: <WarehouseIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/warehouse',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Vendors',
                            icon: <VendorIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/vendors',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Purchase Order',
                            icon: <PurchaseOrderIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/purchase-order',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Clients',
                            icon: <ClientIcon height="20" width="18" className='mr-2' customClasses="c-sidebar-nav-icon" />,
                            to: '/clients',
                            exact: false,
                        },
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Inventory',
                            icon: <CIcon name='cil-building' className='mr-2' />,
                            to: '/inventory-list',
                            exact: false,
                        },
                    ],
                },

            ],
        },
    ],
};