import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    createEditModal: {
        show: false,
        data: undefined,
        type: ''
    },
    serverResponseStatus: {
        apiCalled: false,
        message: '',
        hasError: false
    },
    loader: true,
    warehouseList: [],
}

export const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState,
    reducers: {
        setCreateEditModal: (state, action) => {
            state.createEditModal = action.payload
        },
        setServerResponseStatus: (state, action) => {
            state.serverResponseStatus = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setWarehouseList: (state, action) => {
            state.warehouseList = action.payload
        },
        resetState: () => initialState,
    }
})

export const { setCreateEditModal, setServerResponseStatus, setLoader, setWarehouseList, resetState } = warehouseSlice.actions;
export default warehouseSlice.reducer
