import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { countPerPage } from "src/config/app";

const initialState = {
  loader: false,
  serverResponseStatus: {
    apiCalled: false,
    message: "",
    hasError: false,
  },
  filter: {
    count: countPerPage,
    status_id: 0,
    product_id: 0,
    vendor_id: 0,
    warehouse_id: 0,
  },
};

const reducers = {
  setFilter: (state, filter) => {
    state.filter = filter;
  },
  setStatus: (state, statusId) => {
    state.filter.status_id = statusId;
  },
  setLoader: (state, loader) => {
    state.loader = loader;
  },
  setServerResponseStatus: (state, response) => {
    state.serverResponseStatus = response;
  },
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers,
});

export const { setFilter, setLoader, setServerResponseStatus, setStatus } =
  inventorySlice.actions;

export const useInventorySlice = () => useSelector((state) => state.inventory);
export default inventorySlice.reducer;

// import DisplayAlert from "src/components/DisplayAlert";
// import { countPerPage } from "src/config/app";

// const { createContext, useContext, useState } = require("react");

// const Context = createContext();

// export const InventroyProvider = ({ children }) => {
//   const [loader, setLoader] = useState(false);
//   const [serverResponseStatus, setServerResponseStatus] = useState({
//     apiCalled: false,
//     message: "",
//     hasError: false,
//   });
//   const [filter, setFilter] = useState({
//     count: countPerPage,
//     status_id: 0,
//     product_id: 0,
//     vendor_id: 0,
//     warehouse_id: 0,
//   });

//   const changeStatus = (statusId) => {
//     setFilter((prev) => ({ ...prev, status_id: statusId }));
//   };
//   return (
//     <Context.Provider
//       value={{
//         loader,
//         setLoader,
//         setServerResponseStatus,
//         filter,
//         setFilter,
//         changeStatus,
//       }}
//     >
//       <DisplayAlert serverResponseStatus={serverResponseStatus} />
//       {children}
//     </Context.Provider>
//   );
// };

// export const useInventoryContext = () => useContext(Context);
