
import {
  CToaster,
  CToastBody,
  CToast
} from '@coreui/react'


const DisplayAlert = ({ serverResponseStatus: { hasError, apiCalled, message } }) => {

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  // }, [hasError, apiCalled, message])

  // const [ showAlert]

  console.log(hasError, apiCalled, message, 'from display alert')
  return (



    <CToaster
      position='top-center'
    >
      {
        apiCalled && (

          <CToast
            show={true}
            autohide={5000}
            color={hasError ? 'danger' : 'success'}
            onStateChange={(prevState) => {
            }}
          >

            <CToastBody >
              {message}
            </CToastBody>
          </CToast>
        )
      }
    </CToaster>

  )
}

export default DisplayAlert;

