import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    serverResponseStatus: { apiCalled: false, message: '', hasError: false },
    vendorList: {
        data: [],
        total: 0,
    },
    createEditModal: {
        show: false,
        data: undefined,
        type: '',
    },
}

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setServerResponseStatus: (state, action) => {
            state.serverResponseStatus = action.payload
        },
        setVendorList: (state, action) => {
            state.vendorList = action.payload
        },
        setCreateEditModal: (state, action) => {
            state.createEditModal = action.payload
        },
        resetState: () => initialState,
    }
})

export const { setLoader, setServerResponseStatus, setVendorList, setCreateEditModal, resetState } = vendorSlice.actions;
export default vendorSlice.reducer;


