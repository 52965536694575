import { createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import noticeboardReducer from "./features/noticeboard/noticeboardSlice";
import sidebarToggleReducer from "./features/sidebar/sidebarSlice";
import companyDetailReducer from "./features/companydetail/companyDetailSlice";
import profilePicChangeReducer from "./features/profileUpdate/profileUpdate";
import cashVoucherDashboardReducer from "./features/cash_voucher/dashboardSlice";
import cashVoucherStatementReducer from "./features/cash_voucher/statementSlice";
import cashVoucherPkWiseStatementReducer from "./features/cash_voucher/pkWiseStatementSlice";
import expenseEmployeeDashboardReducer from "src/features/expense/dashboardSlice";
import fileExpenseStandardReducer from "src/features/expense/fileExpenseStandardSlice";
import fileAdvanceExpenseReducer from "src/features/expense/fileAdvanceExpenseSlice";
import fileLocalExpenseReducer from "./features/expense/fileLocalExpenseSlice";
import expenseListReducer from "./features/expense/expenseListSlice";
import viewExpenseReducer from "./features/expense/viewExpense";
import employeeLeavesDashboarReducer from "./features/leaves/employeeDashboardSlice";
import employeeTrailsReducer from "./features/organization/organizationalTrailsSlice";
import moduleAdminLeavesDashboardReducer from "./features/leaves/moduleAdminDashboard";
import moduleAdminLeavesViewReducer from "./features/leaves/moduleAdminView";
import leavePolicyDashboardReducer from "./features/leaves/leavesPolicyDashboardSlice";
import createLeavePolicyReducer from "./features/leaves/createLeavePolicySlice";
import holidayPolicyReducer from "./features/holidayPolicy/holidayPolicySlice";
import editHolidayPolicyReducer from "./features/holidayPolicy/editHolidayPolicySlice";
import weeklyoffPolicyListReducer from "src/features/weeklyoff_policy/weeklyoffPolicyListSlice";
import createWeeklyPolicyListReducer from "src/features/weeklyoff_policy/createWeeklyPolicyListSlice";
import viewWeeklyPolicyListReducer from "src/features/weeklyoff_policy/viewWeeklyPolicyListSlice";
import compoffViewReducer from "src/features/leaves/compoffViewSlice";
import editPolciyMapingReducer from "src/features/policy_maping/editPolicyMapingslice";
import regularisationModuleAdminListReducer from "src/features/regularisation/regularisationModuleAdminListSlice";
import regularisationViewReducer from "src/features/regularisation/regularisationViewSlice";
import attendanceDashboardEmployeeReducer from "src/features/attendance/attendanceDashboardSlice";
import attendancePolicyReducer from "./features/attendance_policy/attendancePolicyDashboardSlice";
import attendancePolicyCreateReducer, {
  attendnacePolicyCreate,
} from "./features/attendance_policy/attendancePolicyCreate";
import actionPendingReducer from "./features/header/actionPendingSlice";
import warehouseReducer from "./features/warehouse/warehouseSlice";
import vendorReducer from "./features/vendor/vendorSlice";
import inventoryReducer from "./features/inventory/inventorySlice";
// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

const store = configureStore({
  reducer: {
    noticeboard: noticeboardReducer,
    sidebarToggle: sidebarToggleReducer,
    companyDetailChange: companyDetailReducer,
    profilePicChange: profilePicChangeReducer,
    cashVoucherDashboard: cashVoucherDashboardReducer,
    cashVoucherStatement: cashVoucherStatementReducer,
    cashVoucherPkWiseStatement: cashVoucherPkWiseStatementReducer,
    expenseEmployeeDashboard: expenseEmployeeDashboardReducer,
    fileExpenseStandard: fileExpenseStandardReducer,
    fileAdvanceExpense: fileAdvanceExpenseReducer,
    fileLocalExpense: fileLocalExpenseReducer,
    expenseList: expenseListReducer,
    viewExpense: viewExpenseReducer,
    employeeLeavesDashboard: employeeLeavesDashboarReducer,
    employeeTrails: employeeTrailsReducer,
    moduleAdminLeavesDashboard: moduleAdminLeavesDashboardReducer,
    moduleAdminLeavesView: moduleAdminLeavesViewReducer,
    leavePolicyDashboard: leavePolicyDashboardReducer,
    createLeavePolicy: createLeavePolicyReducer,
    holidayPolicy: holidayPolicyReducer,
    editHolidayPolicy: editHolidayPolicyReducer,
    weeklyoffPolicyList: weeklyoffPolicyListReducer,
    createWeeklyPolicyList: createWeeklyPolicyListReducer,
    viewWeeklyPolicyList: viewWeeklyPolicyListReducer,
    compOffView: compoffViewReducer,
    editPolicyMaping: editPolciyMapingReducer,
    regularisationModuleAdminList: regularisationModuleAdminListReducer,
    regularisationView: regularisationViewReducer,
    attendanceDashboardEmployee: attendanceDashboardEmployeeReducer,
    attendancePolicyList: attendancePolicyReducer,
    attendancePolicyCreate: attendancePolicyCreateReducer,
    actionPending: actionPendingReducer,
    warehouse: warehouseReducer,
    vendor: vendorReducer,
    inventory: inventoryReducer,
  },
});

export default store;
